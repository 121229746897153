<template>
  <v-row>
    <v-col
      cols="12"
      lg="4"
    >
      <!-- Completed submissions block -->
      <base-material-card
        color="primary"
        icon="mdi-database"
        class="v-card--material-stats"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <template v-slot:after-heading>
          <div class="ml-auto text-right">
            <div
              class="body-3 grey--text font-weight-light"
            >
              {{ $t('dashboard.dp-completed') }}
            </div>

            <h3 class="display-2 font-weight-light text--primary">
              {{ $store.state.main.af.submissions }}
            </h3>
          </div>
        </template>

        <v-col
          cols="12"
          class="px-0"
        >
          <v-divider />
        </v-col>

        <v-btn
          class="mx-1"
          color="warning"
          icon
          x-small
          dark
          :loading="loading"
          @click="$parent.getSubmissions()"
        >
          <v-icon
            size="16"
            class="ml-2 mr-1"
          >
            mdi-sync
          </v-icon>
        </v-btn>

        <span
          class="caption grey--text font-weight-light"
        >
          {{ $t('dashboard.dp-last-sync') }}
          <timeago
            :datetime="$store.state.main.af.syncDate"
            :auto-update="60"
          />
        </span>
      </base-material-card>

      <!-- Last submission block -->
      <base-material-card
        icon="mdi-calendar"
        class="v-card--material-stats mt-8"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <template v-slot:after-heading>
          <div class="ml-auto text-right">
            <div
              class="body-3 grey--text font-weight-light"
            >
              {{ $t('dashboard.last-submission') }}
            </div>

            <h3 class="display-1 font-weight-light text--primary">
              <timeago
                :datetime="$store.state.main.af.syncDate"
                :locale="$i18n.locale"
                :auto-update="60"
              />
            </h3>
          </div>
        </template>

        <v-col
          cols="12"
          class="px-0"
        >
          <v-divider />
        </v-col>

        <v-icon
          color="red"
          size="16"
          class="ml-2 mr-1"
        >
          mdi-clock-outline
        </v-icon>

        <span
          class="caption grey--text font-weight-light"
        >
          {{ formattedDate }}
        </span>
      </base-material-card>
    </v-col>

    <!-- Latest submissions barchart -->
    <v-col
      cols="12"
      lg="8"
    >
      <v-card
        outlined
        elevation="1"
      >
        <v-toolbar
          flat
          color="grey lighten-5"
          dense
        >
          <v-btn
            :color="color"
            class="mr-4"
            fab
            large
            dark
          >
            <v-icon
              class="handle"
            >
              mdi-chart-bar
            </v-icon>
          </v-btn>

          <v-toolbar-title :class="`text-h3 ${color}--text text--darken-1`">
            {{ $t('dashboard.latest') }}
          </v-toolbar-title>
          <v-spacer />
          <!-- Toolbar Menu -->
          <!-- <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                dark
                color="error"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list-item-group
              v-model="selectedItem"
              color="primary"
              @change="listClisk(selectedItem)"
            >
              <v-list>
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text" />
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-list-item-group>
          </v-menu> -->
        </v-toolbar>
        <div
          v-if="loading"
          class="loading"
        >
          <v-progress-linear
            indeterminate
            color="info"
          />
          <!-- ...{{ $t('tooltip.loading') }} -->
        </div>
        <!-- No submission info card -->
        <v-row
          v-if="!hasData"
          justify="center"
        >
          <v-card
            class="grey--text text--darken-0 pa-2"
            color="elevation-0"
            tile
          >
            {{ $t('dashboard.no-submission') }}
          </v-card>
        </v-row>
        <!-- Latest submissions part -->
        <div
          ref="chartDiv"
        >
          <!-- screen {{ sWidth }} -->
          <v-chart
            class="chart"
            :option="bar"
          />
        </div>
      </v-card>
      <div class="py-3" />
    </v-col>
  </v-row>
</template>

<script>
  import Vue from 'vue'
  import { use } from 'echarts/core'
  import VueTimeago from 'vue-timeago'
  import { CanvasRenderer } from 'echarts/renderers'
  import { BarChart } from 'echarts/charts'
  import {
    GridComponent,
    TooltipComponent,
    LegendComponent,
  } from 'echarts/components'
  import VChart, { THEME_KEY } from 'vue-echarts'
  use([
    CanvasRenderer,
    BarChart,
    GridComponent,
    TooltipComponent,
    LegendComponent,
  ])

  Vue.use(VueTimeago, {
    name: 'Timeago', // Component name, `Timeago` by default
    locale: 'en-US', // Default locale
    locales: {
      pt: require('date-fns/locale/pt'),
      ru: require('date-fns/locale/ru'),
      tr: require('date-fns/locale/tr'),
    },
  })
  export default {
    name: 'PieChart',
    components: {
      VChart,
    },
    provide: {
      [THEME_KEY]: 'light',
    },
    data: () => ({
      getDataStatus: false,
      loading: false,
      hasData: true,
      data: {
        hasdata: false,
        labels: [],
        series: [],
      },

      color: 'info',
      sWidth: '',

      selectedItem: '',
      items: [
        { text: 'Real-Time', icon: 'mdi-clock' },
        { text: 'Audience', icon: 'mdi-account' },
        { text: 'Conversions', icon: 'mdi-flag' },
      ],
    }),

    computed: {
      bar () {
        return {
          tooltip: {},
          grid: {
            width: `${this.sWidth - 80}px`,
            left: '5%',
          },
          xAxis: {
            axisLabel: {
              rotate: 90,
            },
            data: this.data.labels,
          },
          yAxis: [{
            type: 'value',
            minInterval: 1,
          }],
          series: [{
            name: this.$t('common.submissions'),
            type: 'bar',
            data: this.data.series[0],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          }],
        }
      },

      formattedDate () {
        const datetime = new Date(this.$store.state.main.af.syncDate)
        const nDate = datetime.getFullYear() + '-' + (datetime.getMonth() + 1) + '-' + datetime.getDate()
        const nTime = datetime.getHours() + ':' + datetime.getMinutes() + ':' + datetime.getSeconds()
        return `${nDate} ${nTime}`
      },
    },

    created () {
      // Start data fetching
      this.getData()
    },

    mounted () {
      //
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.sWidth = this.$refs.chartDiv.offsetWidth
        })
      })
    },

    methods: {

      getData () {
        const main = this.$store.state.main
        const prop = {
          orgid: main.orgid,
          prjid: main.ap.prjid,
          formid: main.afid,
        }

        const ocpuPack = this.$store.state.ocpu.ocpuPack
        // Download data for each attached dashboard blocks
        return new Promise((resolve, reject) => {
          this.loading = true
          // this.getDataStatus = 'loading'
          this.$httpOcpu.post(`/${ocpuPack}/R/ocproglatest/json`, prop)
            .then(response => {
              // console.log(response.data.hasdata[0])
              // console.log(response)
              if (response.status === 201) {
                this.data = response.data
                // this.getDataStatus = true
                this.hasData = response.data.hasdata[0]
                // console.log(this.data)
              }
              this.loading = false
            })
            .catch(error => {
              // console.log(error)
              // this.getDataStatus = 'error'
              return reject(error.message)
            })
        })
      },

      translateMe (val) {
        return this.$t(val)
      },

      listClisk (item) {
        // console.log('clicked ' + item)
      },
    },
  }
</script>
<style scoped>
.chart {
  height: 246px;
}
</style>
